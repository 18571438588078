main div.center-box{
  margin: 50px auto 0px;
  width: 100%;
  max-width: 1040px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

main div.center-box h1{
  text-align: center;
  font-weight: normal;
  margin-bottom: 50px;
}