div.formbox.pet{
  margin-top: 20px;
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom:  1px dotted var(--color_sub);
}

div.photo-thum{
  width: 100px;
  margin-right: 10px;
}

div.photo-thum img{
  width: 100%;
}


div.card-item div.value {
  display: flex;
  flex-direction: row;
  align-items: center;
}

div.pet-icon-small{
  width: 60px;
  height: 60px;
  border-radius: 30px;
  border: 3px solid var(--color_sub);
  overflow: hidden;
}
div.pet-icon-small img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}