footer{
  position: relative;
  margin-top: 100px;
  color: var(--color-gray);
  font: normal normal normal 13px/20px Hiragino Sans;
  letter-spacing: 0px;
  background-color: var(--color-sub);
  z-index: 1;
}
footer.mob{
  display: none;
  height: 80px;
  width: 100%;
  margin: 0;
  position: fixed;
  bottom: 0;
  left:0;
  background-color: #fff;
  border-top: 2px solid var(--color_sub);
  flex-direction: row;
  color:var(--color_sub2)
}
footer.mob div.mob-item{
   width: 25%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;

}
footer.mob div.mob-item div.icon{
 
}
footer.mob div.mob-item div.label{
  font-weight: bold;
  margin-top: 5px;
}
footer div.link{
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

footer div.link div{
  margin:  0 15px;
  cursor: pointer;
}
footer div.link div:hover{
  color:#777;
}
footer div.copyright{
  width: 100%;
  text-align: center;
  font-size: .8em;
}
@media screen and (max-width: 1028px) {
  footer.pc{
    display: none;
  }
  footer.mob{
    display: flex;
  }

}