div.login{
  margin: 80px auto 0px;
  width: 95%;
  max-width: 1040px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

div.login-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.login-item label {
  font-size: 1em;
  font-weight: bold;
  margin-right: 20px;
  width: 200px;
  text-align: right;
}



.login-item div.input input {
  width: 100%;
  box-sizing: border-box;
 
}
.login-item div.input {
  border: 1px solid #000;

  width: 400px;
  background-color: #fff;
}


div.login-btn{
  margin: 0 auto;
}

div.login-box{
  position: fixed;
  top:0;
  left:0;
  z-index: 2;
  width: 100vw;
  height: 100vh;
}

div.login-box div.wrap{
  z-index: 10;
  position: fixed;
  top:0;
  left:0;
  width: 100vw;
  height: 100vh;
}

div.login.upper{
 padding: 30px;
 background-color: #fff;
}

div.new-user{
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

@media screen and (max-width: 1028px) {
  div.login-item {

    flex-direction: column;
    align-items: flex-start;
  }

  div.login-btn{
    width: 100%;
  }
  
  .login-item label {
    margin-right: 0;
    width: 100%;
    text-align: left;
  }

  .login-item div.input {
    width: 100%;
    }
}