div.sitter-list{
  width: 100%;
  margin-top: 70px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

}
div.sitter-card{
  
  width: 322px;
  max-width: 100%;
  margin: 10px;
  padding: 0;
  display: flex;
  flex-direction: column;
  border: 2px solid var(--color_sub);
  background-color: #fff;
  cursor: pointer;
} 

div.sitter-card div.image{
  width: 100%;
  height: 180px;
}

div.sitter-card div.image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
 div.sitter-card div.comment{
  margin-top: 5px;
  padding: 5px 10px;
}

div.sitter-card div.name{
  margin-top: 5px;
  padding: 5px 10px;
}
div.sitter-card div.address{
  margin-top: 5px;
  padding: 5px 10px;
}

div.sitter-detail{
  
}
div.sitter-detail div.calendar{
  width: 520px;
  margin-left: 45px;
  
}
div.sitter-detail div.detail{
  width: calc(100% - 520px);  
  text-align: left;
}
div.sitter-detail div.sitter-image{
  width: 100%;
  height: 250px;
}
div.sitter-detail div.sitter-image img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
div.sitter-detail div.detail h1{
  font-size: 18px;
  margin: 30px 0;
}

div.select-day div.message{
  margin: 10px 0;
}

div.sitter-detail div.time-sheet{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

div.sitter-detail div.time-sheet div.time-item{
  margin-bottom: 5px;
  border-bottom: 1px solid #ccc;
  padding: 8px 5px;
  display: flex;
  flex-direction: row;
  font-size: 1.2em;
}

div.sitter-detail div.time-sheet div.time-item div.time{
  width: 100px;
}
div.sitter-detail div.time-sheet div.time-item div.to-book{
  cursor: pointer;
}
div.sitter-detail div.time-sheet div.time-item div.to-book:hover{
  color:var(--color_red) ;
  font-weight: bold;
}


@media screen and (max-width: 1028px) {
  div.sitter-list{
    justify-content:center;
    margin: 20px auto;
  }

  div.sitter-detail div.detail {
    width:100%;
    text-align: left;
    margin-bottom: 40px;
  }

  div.sitter-detail div.calendar {
    width: 100%;
    margin-left: 0;
  }
  div.sitter-card {
    width: 45%;
    margin: 5px;
    padding: 0;
    display: flex;
    flex-direction: column;
    border: 2px solid var(--color_sub);
    background-color: #fff;
    cursor: pointer;
  }
  div.sitter-card div.image {
    width: 100%;
    height: 150px;
}
} 