div.sidemenu-warp{
  padding: 10px;
    background: #fff;
    margin: 0 0 20px 0;
    border: 1px solid var(--color_sub);
}

div.sidemenu-warp h2{
  width: 100%;
    font-size: 1.3em;
    padding: 5px 0 10px 0;
    border-bottom: 1px solid var(--color_sub)
}

div.sidemenu-warp ul{
  display: flex;
  flex-direction: column;
  padding-inline-start: 20px;
  margin: 0;
}

div.sidemenu-warp ul li{
  margin-bottom: 7px;
  margin: 5px 0;
}

div.sidemenu-warp ul li:first-child{
  margin-top: 15px;
}