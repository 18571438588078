div.header-wrap{
  position: relative;
}
div.header{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}
header {
  padding: 15px 0;
  width: 100%;
  max-width: 1028px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  font-size: 1em;
}

header div.logo{
  width:180px
} 

ul.header-menu{
  display: flex;
  justify-content: center;
  flex-direction:row
}
ul.header-menu li{
  margin: 0 10px;
  cursor: pointer;
} 

div.right-menu{
  display: flex;
  justify-content: center;
  flex-direction:row;
  align-items:center;
  margin-left: auto;

}
div.right-menu  div{
   margin: 0 10px;
   cursor: pointer;
}
div.right-menu  div.button{
  background-color: #000;
  border-radius: 20px;
  padding: 3px  20px;
  color:#fff;
}


@media screen and (max-width: 1028px) {
  ul.header-menu{
    display: none;
  }
  div.right-menu{
    display: none;
  }

}


/**
const headerMenu = {
margin:"0 auto",
display: 'flex',
justifyContent: 'center',
flexDirection:"row"
}
const headerMenuLi = {
fontSize:".8em",
margin:"0 36px",
fontWeight:"bold",
cursor:"pointer"
}
const headerMenuRLi = {
fontSize:".8em",
margin:"0 6px",
fontWeight:"bold",
cursor:"pointer"
}
const headerMenuRight = {
display: 'flex',
justifyContent: 'center',
flexDirection:"row",
alignItems:"center",
cursor:"pointer"
}
const headerMenuRightLogin = {
fontSize:".8em",
margin:"0 36px",
fontWeight:"bold",
cursor:"pointer"
}

const headerMenuRightRegistMob = {
fontSize:".8em",
margin:"0",
fontWeight:"bold",
backgroundColor: config.get("color_black"),
textAlign:"center",
padding:"12px",
cursor:"pointer"
}

const headerMenuRightRegist = {
backgroundColor: config.get("color_black"),
fontSize:".8em",
color:"#fff",
padding:"7px 20px 5px 20px",
display: 'flex',
alignItems:"center",
borderRadius:"20px"
}
**/