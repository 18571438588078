div.btnBox{
  margin:0;
  display:flex;
  justify-content:center;
  align-items:center;
  cursor:pointer;
  width: 50px;
  height: 50px;
  background-color: var(--color_sub);
  color:#fff;
  border-radius: 25px;
  font-size: 20px;
}