div.closebtn{
  margin:0;
  display:flex;
  justify-content:center;
  align-items:center;
  cursor:pointer;
  width: 50px;
  height: 50px;
  font-size: 30px;
  background-color: var(--color_sub);
  color:#fff;
  position: fixed;
  top:5px;
  right:5px;
  z-index: 11;
}