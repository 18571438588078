div.book-calendar{
  width: 100%;
  margin-right: 20px;
}
div.book-calendar-header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 10px;
  margin-bottom: 10px;
}

div.book-calendar.body{

}

div.book-calendar table{
  width: 100%;
  border: 1px solid #fff;
  table-layout: fixed;
}

div.book-calendar table td,
div.book-calendar table th{
   text-align: center;
   padding: 6px;
   border: 1px solid #ccc;
}

div.book-calendar table td{
  background-color: #bbb;
}

div.book-calendar table td.active{
  background-color: var(--color_main);
 
}

div.book-calendar table td.active div.text{
  color:var(--color_red);
  font-weight: bold;
  cursor: pointer;
}