
div.form-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

div.form-item label {
  font-size: 1em;
  font-weight: bold;
  margin-right: 20px;
  width: 200px;
  text-align: right;
}

div.form-item div.input input {
  width: 100%;
  box-sizing: border-box;
 
}


div.form-item div.input {
  border: 1px solid #000;

  width: 400px;
  background-color: #fff;
}

.progress-btn{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}
div.message{
  width: 100%;
  text-align: center;
  color:#f00;
}
div.notice{
  color:#f00;
}

@media screen and (max-width: 1028px) {
  div.form-item {
    flex-direction: column;
  }
  div.form-item label {
    margin-right: 0;
    width: 100%;
    text-align: left;
    margin-bottom: 5px;
  }
  div.form-item div.input {   
    width: 100%;
  }
}