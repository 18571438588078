div.book-detail div.sitter-card{
  margin: 20px 0;
  border: none;
  background-color: inherit;
  cursor: default;
} 

div.book-detaildiv.sitter-card div.comment{
  margin-top: 5px;
  padding: 0;
  font-size: 1em;
}

div.book-detail div.sitter-card div.name{
  margin: 10px 0;
  padding: 0;
  font-size: 1.2em;
  font-weight: bold;
}
div.book-detail div.sitter-card div.address{
  margin-top: 5px;
  padding: 0;
  font-size: 1em;
}

div.book-detail div.book-date-box,
div.book-detail div.book-detail-info{
  padding: 20px;
  background: #fff;
  margin: 0 0 20px 0;
  border: 1px solid var(--color_sub);
}
div.book-detail div.book-date{
  font-size: 1.4em;
  margin: 20px 0 10px 0;
}
div.book-detail div.book-date-box h2,
div.book-detail div.book-detail-info h2{
  width: 100%;
  font-size: 1.3em;
  padding: 5px 0 10px 0;
  border-bottom: 1px solid var(--color_sub);
}

div.pet-list-item{
  display: flex;
  flex-direction: row;
  align-items: center;
}

div.message{
  width: 100%;
  text-align: center;
}

div.book-list{
  display:flex;
  flex-direction: column;
  margin-top: 10px;
  padding: 10px;
}


div.book-list div.book-card{
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

div.book-list div.book-card div.card-item label{
  width: 120px;
}

div.book-list div.book-card div.value{
  width: 200px;
  margin-right: 10px;
}

div.book-items{
  display: flex;
  flex-direction: column;
  margin-top: 20px
}
div.book-items div.book-item{
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
div.book-items div.book-item label{
  font-weight: bold;
  margin-right: 20px;
  width: 100px;
  text-align: right;
}
div.book-items div.book-item div.petitems{
  display: flex;
  flex-direction: row;
  align-items: center;
}
div.book-items div.book-item div.petitem{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
}

div.book-items div.book-item div.petitems div.name{
  margin-top: 3px;
}




@media screen and (max-width: 1028px) {
  div.detail-left {
    width: 100%;
  }
  div.detail-right{
    width: 100%;
    margin-left: 0;
  }

  div.card-item {
    display: flex;
    flex-direction: column;
  }
  div.card-item label {
    width: 100%;
    margin: 10px 0;
    font-weight: bold;
  }


}