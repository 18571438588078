
div.searchBoxWrap{
  margin-top:10px;
  width:100%;
  display: flex;
  justify-content:center
}
div.searchBox{
  display: flex;
  width:860px;
  height:100px;
  background-color:#fff;
  padding:20px 12px;
  border: 5px solid var(--color_sub);
  border-radius: 50px;
  flex-direction: row;

}



.search-box-item{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.search-box-item label{
  font-size: .9em;
  font-weight: bold;
  margin-bottom: 5px;
}

.search-box-item input{
  padding: 0;
}



div.inputBoxWithBorder{
  border-right:1px solid #dddddd
}

div.form{
  margin: 60px auto;
  width: 100%;
}
div.inputBox{
  width:260px;
  padding:0 30px;
}

.search-box-item input {
  padding: 10px;
}

@media screen and (max-width: 1028px) {
  
  div.searchBoxWrap{
    margin:5px auto;

    width:95%;
    display: flex;
    justify-content:center
  }
  div.searchBox{
    border-radius: 7px;
    width:100%;
    height:auto;
    flex-direction: column;
    align-items: center;
    border: 1px solid var(--color_sub);
    background-color:#fff;
    padding:10px;
  }
  div.inputBoxWithBorder{
    border-right:none
  }
  

  div.inputBox{
    width:100%;
    padding:5px;
    border: 1px solid var(--color_sub);
    margin-bottom: 10px;
  }

}